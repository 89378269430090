.nav-bar-custom{
    background-color: #062d5e;
    color: white !important;
}
.nav-bar-auth{
    background-color: #363636;
    color: white !important;
}
 .navbar-brand {
    color: white!important;
    font-weight: 600;
    font-size: 24px;
    padding:15px;
}
.navbar-link {
    border-radius: 10px;
    opacity: inherit;
    color: white!important;
    text-transform: uppercase;
    font-family: 'Source Sans Pro',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 500;
    font-size: 12px!important;
    padding: 15px;
}


a {
    font-family: 'Source Sans Pro',Helvetica,Arial,Lucida,sans-serif;
    text-decoration: none;
    font-size: 14px !important;
    background-color: transparent;
}
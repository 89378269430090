.image_auth {
    width: 250px!important;
    height: 75px!important;
    margin-bottom: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.titulo-auth {
    color: #363636;
    font-weight: 500px;
    text-align: center !important;
    margin-bottom: 20px!important;
    font-family: 'Source Sans Pro',Helvetica,Arial,Lucida,sans-serif !important;
    font-size: 26px !important;
}

.input-auth {
    color: #363636;
    border: 2px solid #d8d8d8;
    padding: 25px;
    border-radius: 15px;
    font-size: 16px;
    margin-bottom: 10px;
    height: 20px;
    width: 100%;
    transition: border .3s ease;
}

.boton {
    margin-top: 30px;
    background-color: #c23635 !important;
    border-radius: 15px;
    width: 100%;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    padding: 15px;
    transition: color .3s ease;
}

.boton_volver {
    margin-top: 10px;
    background-color: #363636;
    border-radius: 15px;
    width: 100%;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    padding: 15px;
    transition: color .3s ease;
}

@media(max-width:760px){
    .auth__box-container {
        background-color: white;
        box-shadow: 0px 3px #363636;
        border-radius: 2px;
        padding: 20px;
        width: 90% !important;
    }  

    .text_auth{
        text-align: center !important;
        font-size: 12px !important;
    }
}

@media(min-width:760px){
    .auth__box-container {
        background-color: white;
        box-shadow: 0px 3px #363636;
        border-radius: 2px;
        padding: 20px;
        width: 250px;
    }  
    .text_auth{
        text-align: center !important;
        font-size: 14px !important;
    }
}
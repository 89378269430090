
// Colors
$primary: #5C62C5;
$dark-grey: #363636;
$light-grey: #d8d8d8;

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;



.titulos{
    margin-top: 30px;
    padding: 15px;
    font-size: 20px;
    text-align: center;
    font-family: 'Source Sans Pro',Helvetica,Arial,Lucida,sans-serif;
}

.search-field{
    padding: 25px!important;
    width: 100% !important;
    font-size: 18px;
    display: inline-block;
    align-self: center;

    text-align: center;
    border:0px;
    background: #F2F1F9;
    
}

.resultados_titulo{
    padding-top: 2em;
    padding: 15px;
    font-size: 20px;
    font-family: 'Source Sans Pro',Helvetica,Arial,Lucida,sans-serif;
}

.table{
    margin-left: 100px;
    padding:25px;
}

.inicio{
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
}

.titulo{
    font-size: 36px;
    font-family: 'Source Sans Pro',Helvetica,Arial,Lucida,sans-serif;
    text-align: left;
}

.card-header{
    font-size:18px ;
    font-weight: 600;
    color: white !important;
    background: linear-gradient(45deg,#39f,#2982cc)!important;
    margin-bottom: 1em ;
    
}
.card {
    margin-top: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #f7f7f7;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .75rem;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.75rem;
}

.bg-custom{
    background: linear-gradient(45deg,#321fdb,#1f1498)!important;
}

.bg-custom2{
    background: linear-gradient(45deg,#39f,#2982cc)!important;
}

.bg-custom3{
    background: linear-gradient(45deg,#f9b115,#f6960b)!important;
}

.bg-custom4{
    background: linear-gradient(45deg,#e55353,#d93737)!important;
}

.text-muted{
    color: #FFFFFF !important;
    margin-bottom: 0em;
    
}

.boton{
    background-color: #4285f4;
    border-radius: 15px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    padding: 15px;
    width: 100%;
    transition: color .3s ease;
}

.boton_validar{
    background-color: #da0034;
    border-radius: 15px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
    padding: 15px;
    width: 100%;
    margin-bottom: 60px;
    transition: color .3s ease;
}